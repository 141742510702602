import React from "react";
// import InfiniteCarousel from "react-leaf-carousel";
import '../assets/css/BrandImages.css'
import alpha from "../assets/Boiler-brands/alpha.png";
import ariston from "../assets/Boiler-brands/ariston.png";
import bosch from "../assets/Boiler-brands/bosch.png";
import glowworm from "../assets/Boiler-brands/glowworm.png";
import greenstar from "../assets/Boiler-brands/greenstar.png";
import ideal from "../assets/Boiler-brands/ideal.png";
import vaillant from "../assets/Boiler-brands/vaillant.png";
import viessman from "../assets/Boiler-brands/viessman.png";
import vokera from "../assets/Boiler-brands/vokera.png";
import worcester from "../assets/Boiler-brands/worcester.png";

export const Brands = () => (
  <div class="image-slider">
    <div>
      <img alt="" src={alpha} />
    </div>
    <div>
      <img alt="" src={ariston} />
    </div>
    <div>
      <img alt="" src={bosch} />
    </div>
    <div>
      <img alt="" src={glowworm} />
    </div>
    <div>
      <img alt="" src={greenstar} />
    </div>
    <div>
      <img alt="" src={ideal} />
    </div>
    <div>
      <img alt="" src={vaillant} />
    </div>
    <div>
      <img alt="" src={viessman} />
    </div>
    <div>
      <img alt="" src={vokera} />
    </div>
    <div>
      <img alt="" src={worcester} />
    </div>
  </div>
);
